import type {PropertyMap} from "./utils/objects";
import {GLOBAL} from "./globals";
import {autoRegister} from "../container";

export interface LinkDefinition {
    href: string;
    hreflang: string;
}

@autoRegister()
export class LanguagesService {

    public availableLanguages(): LanguageProfile[] {
        return this.allLanguages().filter(lang => !this.activeLang().startsWith(lang.id));
    }

    public activeLanguage(): LanguageProfile {
        return this.allLanguages()
                .findFirst(lang => this.activeLang().startsWith(lang.id))
            ?? LanguageProfile.fromLanguageId("de");
    }

    public activeLanguageId(): string {
        return this.activeLanguage().id;
    }

    private activeLang(): string {
        return GLOBAL.htmlElement().getAttribute("lang") ?? "";
    }

    public allLanguages(): LanguageProfile[] {
        const languageLinks = [...GLOBAL.headElement().querySelectorAll<HTMLLinkElement>("link[hreflang][rel='alternate']")];
        return languageLinks.map((link) => new LanguageProfile(link));
    }
}

export class LanguageProfile {
    public id: string;
    public name: string;
    public href: string;
    public numberDecimalSeparator: string;
    public numberGroupSeparator: string;
    public partialDateFormat: Intl.DateTimeFormatOptions;
    public dateFormat: Intl.DateTimeFormatOptions;
    public dateTimeFormat: Intl.DateTimeFormatOptions;

    public constructor(linkDefinition: LinkDefinition) {
        const hrefLang = linkDefinition.hreflang;
        if (!SUPPORTED_LANGUAGES.includes(hrefLang)) {
            throw new Error(`unknown language "${hrefLang}"`);
        }
        this.id = hrefLang;
        this.name = LANGUAGE_NAMES[hrefLang]!;
        this.href = linkDefinition.href;
        this.partialDateFormat = PARTIAL_DATE_FORMATS[hrefLang]!;
        this.dateFormat = DATE_FORMATS[hrefLang]!;
        this.dateTimeFormat = DATETIME_FORMATS[hrefLang]!;
        this.determineNumberSeparators();
    }

    public static fromLanguageId(langId: string): LanguageProfile {
        return new LanguageProfile({
            href: "",
            hreflang: langId
        });
    }

    private determineNumberSeparators(): void {
        const n = 1000.5.toLocaleString(this.id);
        this.numberDecimalSeparator = n.substring(5, 6);
        this.numberGroupSeparator = n.substring(1, 2);
    }
}

const SUPPORTED_LANGUAGES = ["de", "en", "fr", "sv", "zh"];

const LANGUAGE_NAMES: PropertyMap = {
    de: "Deutsch",
    en: "English",
    fr: "Français",
    sv: "Svenska",
    zh: "中文版"
};

const PARTIAL_DATE_FORMATS: PropertyMap<Intl.DateTimeFormatOptions> = {
    de: {month: "long", year: "numeric"},
    en: {month: "long", year: "numeric"},
    fr: {month: "long", year: "numeric"},
    sv: {month: "long", year: "numeric"},
    zh: {month: "long", year: "numeric"}
};

const DATE_FORMATS: PropertyMap<Intl.DateTimeFormatOptions> = {
    de: {day: "2-digit", month: "2-digit", year: "numeric"},
    en: {month: "short", day: "numeric", year: "numeric"},
    fr: {day: "numeric", month: "short", year: "numeric"},
    sv: {day: "numeric", month: "short", year: "numeric"},
    zh: {month: "short", day: "numeric", year: "numeric"}
};

export const DATETIME_FORMATS: PropertyMap<Intl.DateTimeFormatOptions> = {
    de: {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hourCycle: "h24"},
    en: {month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit", second: "2-digit", hourCycle: "h12"},
    fr: {day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"},
    sv: {day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"},
    zh: {month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit", second: "2-digit", hourCycle: "h12"}
};