import {Nav} from "./nav";
import {GLOBAL} from "./globals";
import {ScrollService} from "./scroll";
import {autoRegister, resolve} from "../container";
import {QueryParameters} from "./queryParameters";
import {UrlParts} from "./utils/url";

@autoRegister()
export class SmoothScrolling {
    public constructor(
        private nav: Nav = resolve(Nav),
        private queryParameters: QueryParameters = resolve(QueryParameters),
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
        GLOBAL.bodyElement().addEventListener("click", (event) => {
            const target = event.target as HTMLElement;
            const selector = "a[href*='#']";
            const link: HTMLElement | null = target.matches(selector) ? target : null;
            if (link) {
                this.scroll(event, link);
            }
        });
    }

    public async scrollToAnchor(anchor: string): Promise<void> {
        const urlParts = UrlParts.from(anchor);
        this.queryParameters.applyQueryString(urlParts.query);
        await this.scrollService.scrollToSection(urlParts.anchor)
            .then(() => this.nav.setAnchor(urlParts.anchor));
    }

    private scroll(event: Event, link: HTMLElement): void {
        const href = link.getAttribute("href")!;

        if (!this.nav.isJump(href)) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        void this.scrollToAnchor(href);
    }

}