import {resolve} from "../../container";
import {GLOBAL} from "../../common/globals";
import {ManagingResources} from "../../common/lifetime";
import {prepareEvents} from "../../common/utils/events";
import {Nav} from "../../common/nav";
import {type ErrorRequest, FrontendWatchService} from "./frontendWatchService";
import {EOP_ERRORS, schedule} from "../../common/utils/promises";
import {DeviceDetectorService} from "../../common/device";


export const FRONTEND_WATCH_FAILED = "eop-app-frontend-watch failed.";

const FRONTEND_WATCH_MESSAGE_FRAGMENTS = [
    FRONTEND_WATCH_FAILED,
    "TypeError: Failed to fetch",
    "Load failed",
    "eop-app-frontend-watch"
];

type ErrorInfo = {
    message: string;
    stack: string[];
};

export class EopFrontendWatch extends ManagingResources(HTMLElement) {

    public constructor(
        private frontendWatchService: FrontendWatchService = resolve(FrontendWatchService),
        private nav: Nav = resolve(Nav),
        private deviceDetector: DeviceDetectorService = resolve(DeviceDetectorService)
    ) {
        super();
    }

    public connectedCallback(): void {
        this.listenToErrors();
        this.listenToUnhandledPromiseRejections();
        this.listenToConsoleError();

        this.pingFrontendWatch();
    }

    private listenToErrors(): void {
        prepareEvents(GLOBAL.window())
            .boundTo(this)
            .on("error", event => this.handleError(event));
    }

    public handleError(event: ErrorEvent): void {
        const errorInfo = this.extractErrorInfo(event);
        this.reportError(errorInfo);
    }

    private listenToUnhandledPromiseRejections(): void {
        prepareEvents(GLOBAL.window())
            .boundTo(this)
            .on("unhandledrejection", event => this.handleUnhandledPromiseRejection(event));
    }

    public handleUnhandledPromiseRejection(event: PromiseRejectionEvent): void {
        const errorInfo = this.extractErrorInfo(event.reason);
        this.reportError(errorInfo);
    }

    private extractErrorInfo(errorEvent: any): ErrorInfo {
        const message = errorEvent?.message ?? errorEvent?.toString() ?? "<UNKNOWN ERROR>";
        const stack = errorEvent?.error?.stack?.split("\n")
                .slice(0, 2)
                .map((item: string) => item.trim())
            ?? [];

        return {message, stack};
    }

    private listenToConsoleError(): void {
        const originalConsoleError = console.error;
        console.error = (...args: any[]) => {
            this.reportError({message: [...args].join(" "), stack: []});
            originalConsoleError.apply(console, [...args]);
        };
        this.onDrop(() => console.error = originalConsoleError);
    }

    private reportError(errorInfo: ErrorInfo): void {
        const request: ErrorRequest = {
            message: errorInfo.message,
            stack: errorInfo.stack,
            url: this.nav.href(),
            userAgent: this.deviceDetector.userAgent()
        };

        void schedule(
            this.postError(request).catch(EOP_ERRORS)
        ).as("call-frontend-watch-service");
    }

    private async postError(request: ErrorRequest): Promise<void> {
        if (FRONTEND_WATCH_MESSAGE_FRAGMENTS.some(fragment => request.message.includes(fragment))) {
            // prevent infinity loop
            try {
                await this.frontendWatchService.submitError(request, "Failed to call eop-app-frontend-watch. Will not report this error.");
            } catch (e) {
                console.warn(e.message);
            }
        } else {
            await this.frontendWatchService.submitError(request, FRONTEND_WATCH_FAILED);
        }
    }

    private async pingFrontendWatch(): Promise<void> {
        await this.frontendWatchService.submitPing(FRONTEND_WATCH_FAILED);
    }
}

customElements.define("eop-frontend-watch", EopFrontendWatch);