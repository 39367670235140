import {ManagingResources} from "../common/lifetime";
import {autoRegister, resolve} from "../container";
import {prepareEvents} from "../common/utils/events";
import {GLOBAL} from "../common/globals";
import {Tracking} from "./tracking";
import {Nav} from "../common/nav";
import type {LinkTrackingData, TrackableMouseEvent} from "./listener";
import {ClickTrackingContext} from "./trackingContext";

@autoRegister()
export class ClickTracking {

    public constructor(
        private tracking: Tracking = resolve(Tracking),
        private nav: Nav = resolve(Nav)
    ) {
    }

    public handleTextlink(event: Event, element: Element, targetUrl?: string): void {
        const context = new ClickTrackingContext(element);
        if (!context) {
            return;
        }

        this.markAsTracked(event);
        this.tracking.textlinkInteract({
            label: context.getEventLabel(),
            contentId: ClickTrackingContext.makeContentId(event),
            linkData: this.linkData(targetUrl)
        });
    }

    public handleBodyClick(event: TrackableMouseEvent): void {
        if (event.hasBeenTracked) {
            return;
        }

        const context = ClickTrackingContext.fromEvent(event);
        if (!context) {
            return;
        }

        const clickedElement = context.element;
        const contentId = ClickTrackingContext.makeContentId(event);

        if (!this.isInsideMainContent(event)) {
            //TODO: handle nav & footer interact
            return;
        }

        if (clickedElement instanceof HTMLAnchorElement) {
            const targetUrl = clickedElement.getAttribute("href") ?? "";
            const linkData = this.linkData(targetUrl);

            if (clickedElement.getAttribute("data-eventelement") === "button") {
                this.tracking.buttonInteract({
                    label: context.getEventLabel(),
                    contentId: contentId,
                    linkData: linkData
                });
            } else {
                this.tracking.textlinkInteract({
                    label: context.getEventLabel(),
                    contentId: contentId,
                    linkData: linkData
                });
            }
        }
    }

    private markAsTracked(event: Event): void {
        Object.defineProperty(event, "hasBeenTracked", {value: true, writable: true});
    }

    private linkData(targetUrl?: string): LinkTrackingData | undefined {
        if (!targetUrl) {
            return undefined;
        }
        return {
            targetUrl: this.nav.absoluteHrefFrom(targetUrl),
            isExternal: !this.nav.isInternalLink(targetUrl)
        };
    }

    private isInsideMainContent(event: Event): boolean {
        const eventPath: Element[] = Array.from(event.composedPath()
            .filter(element => element instanceof Element)
            .map(element => element));
        return eventPath
            .filter(elm => {
                const tagName = elm.tagName.toLowerCase();
                return tagName === "header" || tagName === "footer" || tagName === "eop-chapter-navigation";
            }).length === 0;
    }
}

export class EopClickTracking extends ManagingResources(HTMLElement) {

    public constructor(private clickTrackingListener: ClickTracking = resolve(ClickTracking)) {
        super();
    }

    public connectedCallback(): void {
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(this)
            .on("click", ev => this.clickTrackingListener.handleBodyClick(ev as TrackableMouseEvent));
    }
}

customElements.define("eop-click-tracking", EopClickTracking);