import {customElement, property, queryAll} from "lit/decorators.js";
import Styles from "./accordion.lit.scss";
import {resolve} from "../../../container";
import {ScrollService} from "../../../common/scroll";

import {AutoInitializing} from "../../../common/elements";
import {ManagingResources} from "../../../common/lifetime";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {intRangeClosed} from "../../../bootstrap/common/arrays";
import {ResizeObserverFactory} from "../../../common/observation";
import {noop} from "../../../common/utils/functions";

//has to be the same duration as expansion animation in scss
export const EXPANSION_DURATION = 500;
export const NEGATIVE_MARGIN = 32;
export const HEADLINE_CONTENT_GAP = 16;

@customElement("eop-accordion")
export class EopAccordion extends AutoInitializing(ManagingResources(LitElement)) {

    public static readonly styles = Styles;

    @property({attribute: "accordion-config"})
    public config: string;
    @queryAll(".accordion-item")
    private items: NodeListOf<HTMLElement>;
    private singleActive: boolean;
    private itemCount: number;
    private verticalAligned: boolean;
    private layoutElement: Element;
    private previousLayoutElementHeight: number;

    private resizeObserver: ResizeObserver;

    public constructor(
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.singleActive = this.config === "single";
        this.itemCount = this.querySelectorAll(".accordion-item-content").length;
        this.resizeObserver = this.resizeObserverFactory.create(() => this.updateHeights());
        this.verticalAligned = !!this.closestThat(e => e.classList.contains("vertical-align"));
        this.layoutElement = this.closestThat(e => e.classList.contains("layout-element"))!;
    }

    public render(): TemplateResult {
        return html`
            <div class="accordion">${this.renderItems()}</div>
        `;
    }

    public renderItems(): TemplateResult[] {
        return intRangeClosed(1, this.itemCount).map(id => this.renderItem(id));
    }

    public renderItem(id: number): TemplateResult {
        const handleToggle = (e: Event): void => this.toggle(e, id);
        return html`
            <div class="accordion-item">
                <div tabindex="0"
                     class="tabbable accordion-item-headline-container"
                     data-tracking-label=${this.getTrackingLabel(id)}
                     @click=${handleToggle}
                     @keydown=${handleToggle}
                >
                    <slot name="accordion-item-headline-${id}"></slot>
                </div>
                <div class="accordion-item-content-container">
                    <slot name="accordion-item-content-${id}"></slot>
                </div>
            </div>
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.updateHeights();
        this.items.forEach(item => this.resizeObserver.observe(this.assignedItemForAccordionItem(item)));
    }

    private getTrackingLabel(id: number): string {
        return this.querySelector(`[slot=accordion-item-headline-${id}]`)!.getAttribute("data-tracking-label") || "";
    }

    private updateHeights(): void {
        this.items.forEach(item => this.setCalculatedHeight(item));
    }

    private setCalculatedHeight(item: HTMLElement): void {
        const height = this.assignedItemForAccordionItem(item).clientHeight;
        item.style.setProperty("--collapsible-height", `${height}px`);
    }

    private assignedItemForAccordionItem(item: HTMLElement): HTMLElement {
        return item.querySelector<HTMLSlotElement>(".accordion-item-content-container slot")!.assignedElements()[0] as HTMLElement;
    }

    private toggle(e: Event, id: number): void {
        if (e instanceof KeyboardEvent && e.key !== " " && e.key !== "Enter" && e.key !== "Escape") {
            return;
        }

        const toggledItem = this.items.item(id - 1);
        toggledItem.toggleAttribute("open");
        this.previousLayoutElementHeight = this.layoutElement.clientHeight;

        if (this.isOpening(toggledItem)) {
            const closingItem = this.getClosingItem(toggledItem);
            if (closingItem && this.singleActive) {
                this.scrollToItem(toggledItem,
                    this.calculateClosedMarginBetween(toggledItem, closingItem) +
                    this.offsetFromVerticalAlignment(closingItem, false) +
                    this.offsetFromVerticalAlignment(toggledItem, true));
                closingItem.removeAttribute("open");
                return;
            }
            this.scrollToItem(toggledItem, this.offsetFromVerticalAlignment(toggledItem, true));
        } else {
            this.scrollToItem(toggledItem, this.offsetFromVerticalAlignment(toggledItem, false));
        }
    }

    private isOpening(toggledItem: HTMLElement): boolean {
        return toggledItem.hasAttribute("open");
    }

    private getClosingItem(openingItem: HTMLElement): HTMLElement | null {
        return Array.from(this.items).findFirst(item => item.hasAttribute("open") && item !== openingItem) ?? null;
    }

    private calculateClosedMarginBetween(openingItem: HTMLElement, closingItem: HTMLElement): number {
        if (this.openingItemBeforeClosingItem(openingItem, closingItem)) {
            return closingItem.style.getPropertyValue("--collapsible-height")
                    .toInt()
                + HEADLINE_CONTENT_GAP;
        }
        return 0;
    }

    private openingItemBeforeClosingItem(openingItem: HTMLElement, closingItem: HTMLElement): boolean {
        return closingItem.compareDocumentPosition(openingItem) === this.DOCUMENT_POSITION_FOLLOWING;
    }

    private offsetFromVerticalAlignment(toggledItem: HTMLElement, opening: boolean): number {
        if (opening) {
            return this.currentlyVerticalAligned() ? 0.5 * this.getHeight(toggledItem) : 0;
        } else {
            return this.currentlyVerticalAligned() ? -0.5 * this.getHeight(toggledItem) : 0;
        }
    }

    private currentlyVerticalAligned(): boolean {
        if (!this.verticalAligned) {
            return false;
        }
        return this.computedStyle().getPropertyValue("--grid-columns").toInt() !== 12;
    }

    private getHeight(item: HTMLElement): number {
        return item.style.getPropertyValue("--collapsible-height").toInt() + HEADLINE_CONTENT_GAP;
    }

    private scrollToItem(item: HTMLElement, closedMargin: number): void {
        void this.scrollService.scrollToElement(item, closedMargin + NEGATIVE_MARGIN, EXPANSION_DURATION, EXPANSION_DURATION)
            .then(this.compensateLayoutBodyHeightChange());
    }

    private compensateLayoutBodyHeightChange() {
        if (this.currentlyVerticalAligned()) {
            return () => this.scrollService.scrollRelative(0.5 * (this.layoutElement.clientHeight - this.previousLayoutElementHeight), EXPANSION_DURATION);
        } else {
            return () => noop();
        }
    }
}