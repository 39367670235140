import {isString} from "../../bootstrap/common/strings";
import {LanguageProfile, LanguagesService} from "../languages";
import {autoRegister, resolve} from "../../container";
import {UnLitElement} from "../elements";
import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";

const numberGroupSplitter = /\B(?=(?:\d{3})+(?!\d))/g; // matches the empty space every third digit from the right end of an integer (thousands separator)

@autoRegister()
export class LocatedNumbers {
    private activeLanguage: LanguageProfile;

    public constructor(languageService: LanguagesService = resolve(LanguagesService)) {
        this.activeLanguage = languageService.activeLanguage();
    }

    public parseInteger(value: any): number {
        let valueToParse;

        if (isString(value)) {
            valueToParse = value.replace(new RegExp(`\\${this.activeLanguage.numberGroupSeparator}`, "g"), "");
        } else {
            valueToParse = value;
        }

        return parseInt(valueToParse);
    }

    public formatNumber(value: number, useNumberGroupSeparator: boolean = true): string {
        return this.formatNumberString(value.toString(), useNumberGroupSeparator);
    }

    public formatNumberString(value: string, useNumberGroupSeparator: boolean = true, allowZeroDecimal: boolean = true): string {
        const [beforeDecimalSeparator, afterDecimalSeparator] = value.split(".");
        const prefix = useNumberGroupSeparator
            ? beforeDecimalSeparator.replace(numberGroupSplitter, this.activeLanguage.numberGroupSeparator)
            : beforeDecimalSeparator;
        const suffix = afterDecimalSeparator === undefined
            ? ""
            : this.formatDecimals(afterDecimalSeparator, allowZeroDecimal);

        return `${prefix}${suffix}`;
    }

    private formatDecimals(decimals: string, allowZeroDecimal: boolean): string {
        return allowZeroDecimal || decimals.toInt() > 0
            ? `${this.activeLanguage.numberDecimalSeparator}${decimals}`
            : "";
    }
}

@customElement("eop-located-numbers")
export class EopLocatedNumbers extends UnLitElement {

    @property({attribute: "number"})
    public number: string;
    @property({attribute: "allow-zero-decimal", type: Boolean})
    public allowZeroDecimal: boolean = false;

    public constructor(private locatedNumbers: LocatedNumbers = resolve(LocatedNumbers)) {
        super();
    }

    public render(): TemplateResult {
        return html`${this.locatedNumbers.formatNumberString(this.number, true, this.allowZeroDecimal)}`;
    }
}