import {Resolution} from "../../../common/resolution";
import {IMAGE_BREAKPOINTS, ImageBreakpoint} from "../../../common/resolutionConstants";
import {Cookieflag} from "../../../common/cookieflag";
import {isDefined} from "../../../common/utils/basics";
import {ImageDimension, parseResolutionSource, ResolutionSource} from "./imageUtils";
import {resolve} from "../../../container";
import {Timeout} from "../../../common/timeout";
import {IntersectionObserverFactory, onceIntersected, ResizeObserverFactory} from "../../../common/observation";
import {customElement, property, query, state} from "lit/decorators.js";
import {html, type PropertyValues, type TemplateResult} from "lit";
import {RetinaSourceCreator} from "./retinaSourceCreator";
import {eopCustomEvent} from "../../../common/eventBus";
import {UnLitElement} from "../../../common/elements";
import {ifDefined} from "lit/directives/if-defined.js";

export const RESIZE_TIMEOUT_DELAY = 300;
const FIXED_ASPECT_RATIO_CLASS = "fixed-aspect-ratio";

type SrcData = {
    src?: string;
    srcset?: string;
};

@customElement("eop-responsive-image")
export class ResponsiveImageElement extends UnLitElement {

    private resizeObserver: ResizeObserver;
    private intersectionObserver: IntersectionObserver;
    private resizeTimeout: Promise<Promise<void>> | undefined;
    private imageDimension: ImageDimension;
    private breakpointSources: Map<ImageBreakpoint, string> | undefined;
    private useFixedAspectRatio: boolean;
    private resolutionSource: ResolutionSource;

    @property({attribute: "image-src", reflect: true})
    private imageSrc: string;
    @property({attribute: "image-alt"})
    private imageAlt: string;
    @property({attribute: "fixed-aspect-ratio"})
    private fixedAspectRatio: string;
    @property({attribute: "intrinsic-aspect-ratio", type: Boolean, reflect: true})
    private intrinsicAspectRatio: boolean = false;
    @property({attribute: "reverse-src-order", type: Boolean})
    private reverseSrcOrder: boolean = false;
    @property({attribute: "max-crop-threshold", type: Number})
    private maxCropThreshold: number | undefined;
    @state()
    private src: string;
    @state()
    private srcset: string;
    @state()
    private inactive: boolean;

    @query("img")
    private imageElement: HTMLImageElement;

    public constructor(
        private cookieflag: Cookieflag = resolve(Cookieflag),
        private timeout: Timeout = resolve(Timeout),
        private resolution: Resolution = resolve(Resolution),
        private retinaSourceCreator: RetinaSourceCreator = resolve(RetinaSourceCreator),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        super();
        this.updateImageDimension();
        this.src = "";
        this.resolutionSource = new ResolutionSource();
        this.inactive = true;

        this.classList.add("eop-image");
    }

    public render(): TemplateResult {
        if (this.cookieflag.isSetFor("enbw-disable-responsive-image") || this.inactive) {
            return html`
                <img src="" alt="" class="responsive-image">
            `;
        } else {
            const srcData = this.currentSrcData();
            return html`
                <img src=${srcData.src} srcset=${srcData.srcset} alt=${ifDefined(this.imageAlt)} class="responsive-image">
            `;
        }
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.useFixedAspectRatio = this.fixedAspectRatio === "true";
        this.resizeObserver = this.resizeObserverFactory.create(() => this.scheduleImageUpdate());

        this.intersectionObserver = this.intersectionObserverFactory.create(onceIntersected(() => {
            if (this.willTriggerResizeOnImageLoad()) {
                this.onInitialLoad(() => {
                    this.updateImageDimension();
                });
            }

            this.resizeObserver.observe(this);
            this.inactive = false;
            this.updateSource().then(() => {
                this.onInitialLoad(() => this.dispatchEvent(eopCustomEvent("imageLoaded")));
            });
        }), {threshold: 0, rootMargin: "150% 0% 150% 0%"});
        this.intersectionObserver.observe(this);
    }

    public disconnectedCallback(): void {
        this.resizeObserver.disconnect();
        this.intersectionObserver.disconnect();
        super.disconnectedCallback();
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.breakpointSources = this.extractSources();
    }

    private currentSrcData(): SrcData {
        this.resolutionSource = this.extractCurrentImageSource();
        this.imageDimension = this.determineImageDimension();
        this.imageDimension.limitTo(this.resolutionSource.width, this.resolutionSource.height);
        if (!this.imageDimension.isValid()) {
            return {};
        }

        this.src = this.retinaSourceCreator.resolutionUrlBase(this.resolutionSource, this.imageDimension);
        this.srcset = this.retinaSourceCreator.srcsetFor(this.resolutionSource, this.imageDimension);

        return {
            src: this.src,
            srcset: this.srcset
        };
    }

    private extractSources(): Map<ImageBreakpoint, string> {
        const artDirectedSources = new Map<ImageBreakpoint, string>();
        for (const key of Object.keys(IMAGE_BREAKPOINTS)) {
            const src = this.getAttribute("src-" + IMAGE_BREAKPOINTS[key].min);
            if (src) {
                artDirectedSources.set(IMAGE_BREAKPOINTS[key], src);
            }
        }
        return artDirectedSources;
    }

    private willTriggerResizeOnImageLoad(): boolean {
        return this.clientWidth !== 0
            && this.clientHeight === 0;
    }

    public scheduleImageUpdate(): void {
        this.useFixedAspectRatio = this.fixedAspectRatio === "true" && !this.shouldBeCropped();

        if (this.dimensionUnchanged()) {
            return;
        }
        if (this.resizeTimeout) {
            this.timeout.cancel(this.resizeTimeout);
        }
        this.imageElement.classList.add("delayed-loading");
        this.resizeTimeout = this.timeout.delay(() => this.updateSource(), RESIZE_TIMEOUT_DELAY);
    }

    private async onInitialLoad(callback: () => void): Promise<void> {
        await this.updateComplete;
        this.imageElement.addEventListener("load", () => callback(), {once: true});
    }

    private updateImageDimension(): void {
        this.imageDimension = ImageDimension.from(this);
    }

    private async updateSource(): Promise<void> {
        this.resolutionSource = this.extractCurrentImageSource();
        this.addSource();

        await this.updateComplete;

        this.imageElement.addEventListener("load", () => {
            this.imageElement.classList.remove("delayed-loading");
        }, {once: true});
    }

    private extractCurrentImageSource(): ResolutionSource {
        const currentBreakpoint = this.resolution.getBreakpoint();
        let breakpointSource: string | null;
        if (this.reverseSrcOrder) {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => currentBreakpoint < breakpoint.min)
                .map(breakpoint => this.getSourceForResolution(breakpoint))
                .filter(isDefined)
                .filter(src => !src.startsWith("{{"))
                .first();
        } else {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => breakpoint.min <= currentBreakpoint)
                .map(breakpoint => this.getSourceForResolution(breakpoint))
                .filter(isDefined)
                .filter(src => !src.startsWith("{{"))
                .last();
        }
        return parseResolutionSource(breakpointSource ?? this.imageSrc) ?? new ResolutionSource();
    }

    private addSource(): void {
        this.resolutionSource = this.extractCurrentImageSource();
        this.imageDimension = this.determineImageDimension();
        if (!this.imageDimension.isValid()) {
            return;
        }
        const imageLargeEnough = this.imageDimension.isApplicableTo(this.resolutionSource);
        this.classList.toggle(FIXED_ASPECT_RATIO_CLASS, this.useFixedAspectRatio && imageLargeEnough);

        this.imageDimension.limitTo(this.resolutionSource.width, this.resolutionSource.height);

        this.srcset = this.retinaSourceCreator.srcsetFor(this.resolutionSource, this.imageDimension);
        this.src = this.retinaSourceCreator.resolutionUrlBase(this.resolutionSource, this.imageDimension);
    }

    private getSourceForResolution(imageBreakpoint: ImageBreakpoint): string | undefined {
        if (!this.breakpointSources) {
            this.breakpointSources = this.extractSources();
        }
        return this.breakpointSources.get(imageBreakpoint);
    }

    private determineImageDimension(): ImageDimension {
        const imageDimension = ImageDimension.from(this);
        if (this.useFixedAspectRatio || this.intrinsicAspectRatio) {
            imageDimension.applyAspectRatio(this.resolutionSource.width / this.resolutionSource.height);
        }
        return imageDimension;
    }

    private dimensionUnchanged(): boolean {
        const currentDimension = ImageDimension.from(this);
        return this.imageDimension.matches(currentDimension);
    }

    private shouldBeCropped(): boolean {
        if (!this.maxCropThreshold) {
            return false;
        }

        if (!this.resolutionSource.validate()) {
            return false;
        }

        let relativeImageArea;

        const imgRatio = this.resolutionSource.width / this.resolutionSource.height;
        const containerRatio = this.clientWidth / this.clientHeight;

        if (containerRatio > imgRatio) {
            relativeImageArea = this.clientWidth * this.resolutionSource.height * (this.clientWidth / this.resolutionSource.width);
        } else {
            relativeImageArea = this.clientHeight * this.resolutionSource.width * (this.clientHeight / this.resolutionSource.height);
        }

        const containerArea = this.clientWidth * this.clientHeight;
        const overlapRatio = containerArea / relativeImageArea;

        return overlapRatio > 1 - this.maxCropThreshold;
    }
}