import {eopCustomEvent} from "../../../../common/eventBus";
import type {NavigationItem} from "../common/navModel";

export const NAVIGATION_DOWN_EVENT = "eop-navigation-down";
export const NAVIGATION_UP_EVENT = "eop-navigation-up";
export const MOBILE_MENU_OPENED = "eop-mobile-menu-opened";
export const MOBILE_MENU_CLOSED = "eop-mobile-menu-closed";

export type NavigationDownEvent = CustomEvent<NavigationItem>
export type NavigationUpEvent = CustomEvent<void>
export type MobileNavigationOpenEvent = CustomEvent<void>
export type MobileNavigationCloseEvent = CustomEvent<void>

export function navigationDownEvent(link: NavigationItem): NavigationDownEvent {
    return eopCustomEvent(NAVIGATION_DOWN_EVENT, link);
}

export function navigationUpEvent(): NavigationUpEvent {
    return eopCustomEvent(NAVIGATION_UP_EVENT);
}

export function mobileNavigationOpenedEvent(): MobileNavigationOpenEvent {
    return eopCustomEvent(MOBILE_MENU_OPENED);
}

export function mobileNavigationClosedEvent(): MobileNavigationCloseEvent {
    return eopCustomEvent(MOBILE_MENU_CLOSED);
}