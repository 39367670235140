import type {ElementViewTrackingData, InputTrackingData, InteractionTrackingData, VideoEventTrackingData, ViewTrackingData} from "./trackingData";
import type {TrackingListener} from "./listener";
import {autoRegister} from "../../container";

export function makeId(str?: string): string {
    if (str === "" || str === "/") {
        return ".homepage";
    }
    if (!str) {
        return ".null";
    }
    if (/^\/index$/.exec(str)) {
        return ".homepage";
    }
    if (/^\/index_en$/.exec(str)) {
        return ".homepage_en";
    }
    return str.toLowerCase()
        .replace(/\/index$/, "")
        .replace(/\/index_en$/, "")
        .replace(/\/$/, "")
        .replace(/ä/g, "ae")
        .replace(/ö/g, "oe")
        .replace(/ü/g, "ue")
        .replace(/ß/g, "ss")
        .replace(/\//g, ".")
        .replace(/[^0-9a-zA-Z.:?#\[\]@$&'()*+,°;={}\-_~<>|!§%^\\]+/g, "_");
}

@autoRegister()
export class Tracking {

    private listeners: TrackingListener[] = [];

    public view(data?: ViewTrackingData): void {
        this.listeners.forEach(listener => listener.view(data ?? {}));
    }

    public interaction(data: InteractionTrackingData): void {
        this.listeners.forEach(listener => listener.interaction(data));
    }

    public elementView(data?: ElementViewTrackingData): void {
        this.listeners.forEach(listener => listener.elementView(data ?? {}));
    }

    public errorView(data?: ViewTrackingData): void {
        this.listeners.forEach(listener => listener.errorView(data ?? {}));
    }

    public input(data?: InputTrackingData): void {
        this.listeners.forEach(listener => listener.input(data ?? {}));
    }

    public videoEvent(data?: VideoEventTrackingData): void {
        this.listeners.forEach(listener => listener.videoEvent(data ?? {}));
    }
}