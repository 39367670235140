import {autoRegister} from "../container";
import type {Lifetime} from "./lifetime";
import {DefaultLifetime} from "./lifetime";


export class EventBusEvent<T = any> {
    public constructor(public type: string, public data: T) {
    }
}

export type EventBusCallback<T = any> = (event: EventBusEvent<T>) => void;

@autoRegister()
export class EventBus {
    private eventCallbacks: Map<string, EventBusCallback[]>;
    private eventQueue: Map<string, any[]>;

    public constructor() {
        this.eventCallbacks = new Map();
        this.eventQueue = new Map();
    }

    public on<T = any>(eventType: string, callback: EventBusCallback<T>, lifetime?: Lifetime): void {
        const registeredCallbacks = this.callbacksFor(eventType);
        registeredCallbacks.push(callback);
        this.eventCallbacks.set(eventType, registeredCallbacks);

        const queuedEvents = this.eventQueue.get(eventType) ?? [];
        queuedEvents.forEach((data: any) => callback(new EventBusEvent(eventType, data)));
        if (lifetime) {
            lifetime.onDrop(() => this.remove(eventType, callback));
        }
    }

    public once<T = any>(eventType: string, callback: EventBusCallback<T>): void {
        const abortController = new AbortController();
        this.on(eventType, (ev: EventBusEvent<T>) => {
            callback(ev);
            abortController.abort();
        }, new DefaultLifetime(abortController));
    }

    public dispatchEvent<T = any>(eventType: string, eventData: T = {} as any): void {
        // clone needed here to prevent concurrent modification of array when de-registering
        const registeredCallbacks = this.callbacksFor(eventType).clone();

        if (registeredCallbacks.isEmpty()) {
            const queuedData = this.eventQueue.get(eventType) ?? [];
            queuedData.push(eventData);
            this.eventQueue.set(eventType, queuedData);
        } else {
            registeredCallbacks.forEach(callback => callback(new EventBusEvent(eventType, eventData)));
        }
    }

    public remove(eventType: string, callback: EventBusCallback): void {
        this.callbacksFor(eventType).removeAll(callback);
    }

    private callbacksFor(eventType: string): EventBusCallback[] {
        return this.eventCallbacks.get(eventType) ?? [];
    }
}

export function eopCustomEvent<DETAIL>(name: string, details?: DETAIL): CustomEvent<DETAIL> {
    return new CustomEvent(name, {bubbles: true, composed: true, detail: details});
}