import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, queryAssignedElements, state} from "lit/decorators.js";
import Styles from "./macherbusVoting.lit.scss";
import type {EopMacherbusCategory} from "./macherbusCategory";
import {MACHERBUS_CATEGORY_VOTE_EVENT, type MacherbusCategoryVoteEvent} from "./macherbusEvents";
import {resolve} from "../../container";
import {MacherbusStorage} from "./macherbusStorage";
import {templateContaining} from "../../common/utils/html";
import {AutoInitializing} from "../../common/elements";

@customElement("eop-macherbus-voting")
export class EopMacherbusVoting extends AutoInitializing(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "voting-id"})
    public votingId: string;
    @state()
    private votedCategories: string[];
    @queryAssignedElements({slot: "macherbus-category"})
    private categories: Array<EopMacherbusCategory>;

    private checkoutLayer: string;

    public constructor(private macherbusStorage: MacherbusStorage = resolve(MacherbusStorage)) {
        super();
        this.votedCategories = [];
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.addEventListener(MACHERBUS_CATEGORY_VOTE_EVENT, (event: Event) => {
            const eventDetails = (event as MacherbusCategoryVoteEvent).detail;
            this.updateVotedCategories(eventDetails.categoryId);
            this.macherbusStorage.saveVote(this.votingId, eventDetails.categoryId, eventDetails.projectId);
        });

        const checkoutLayerTemplate = this.querySelector<HTMLTemplateElement>(".macherbus-checkout-layer-template");
        if (checkoutLayerTemplate !== null) {
            this.checkoutLayer = checkoutLayerTemplate.innerHTML;
        }
    }

    public render(): TemplateResult {
        const checkoutContent = templateContaining(this.checkoutLayer);
        checkoutContent.setAttribute("data-id", "content");
        return html`
            <slot name="macherbus-category" class="macherbus-categories"></slot>

            <div class="action-footer">
                <eop-inline-modal-dialog content="content">
                    <button class="finalize-voting-button primary" data-eventelement="button" type="button"
                            ?disabled=${!this.allVoted()}>
                        <eop-msg key="FINALIZE_VOTING_LABEL"></eop-msg>
                    </button>

                    ${checkoutContent}
                </eop-inline-modal-dialog>
            </div>
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.categories.forEach(category => {
            const votedProjectId = this.macherbusStorage.votedProjectInCategory(this.votingId, category.getCategoryId());
            if (votedProjectId) {
                category.voteFor(votedProjectId);
            }
        });
    }

    private updateVotedCategories(categoryId: string): void {
        if (!this.votedCategories.includes(categoryId)) {
            const newVotedCategories = Array.from(this.votedCategories);
            newVotedCategories.push(categoryId);
            this.votedCategories = newVotedCategories;
        }
    }

    private allVoted(): boolean {
        return this.categories.length > 0
            && this.votedCategories.length === this.categories.length;
    }
}