import {parseResolutionSource, ResolutionSource} from "./imageUtils";
import {clamp} from "../../../bootstrap/common/numbers";
import {eopCustomEvent} from "../../../common/eventBus";

const IMAGE_TOO_WIDE_FOR_PARENT_CLASS = "image-too-wide-for-parent";
const LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS = "landscape-image-too-tall-for-parent";
const PORTRAIT_IMAGE_CLASS = "portrait-image";
const LANDSCAPE_IMAGE_CLASS = "landscape-image";

export class PlainImageLoadListener {
    public constructor(private imageElementProvider: () => HTMLImageElement, private parent: HTMLElement) {
    }

    public imageLoaded(src: string): void {
        const imageElement = this.imageElementProvider();
        const focusData = parseResolutionSource(src) ?? new ResolutionSource();

        const parentWidth = this.parent.outerWidth();
        const parentHeight = this.parent.outerHeight();
        const imageWidth = imageElement.outerWidth();
        const imageHeight = imageElement.outerHeight();
        const parentAspectRatio = parentWidth / parentHeight;
        const aspectRatio = imageWidth / imageHeight;
        let focusTranslateX = 50;
        let focusTranslateY = 50;

        imageElement.classList.remove(IMAGE_TOO_WIDE_FOR_PARENT_CLASS);
        imageElement.classList.remove(LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS);

        if (aspectRatio > parentAspectRatio) {
            focusTranslateX = focusData.focusX ?? focusTranslateX;
            focusTranslateX = clamp(focusTranslateX, 100 * parentAspectRatio / (2 * aspectRatio), 100 * (1 - parentAspectRatio / (2 * aspectRatio)));
            imageElement.classList.add(IMAGE_TOO_WIDE_FOR_PARENT_CLASS);
        }
        if (aspectRatio < parentAspectRatio) {
            focusTranslateY = focusData.focusY ?? focusTranslateY;
            focusTranslateY = clamp(focusTranslateY, 100 * aspectRatio / (2 * parentAspectRatio), 100 * (1 - aspectRatio / (2 * parentAspectRatio)));
            if (aspectRatio > 1) {
                imageElement.classList.add(LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS);
            }
        }
        imageElement.style.setProperty("--focus-translate", `-${focusTranslateX}% -${focusTranslateY}%`);

        if (aspectRatio <= 1) {
            imageElement.classList.add(PORTRAIT_IMAGE_CLASS);
        } else if (aspectRatio > 1) {
            imageElement.classList.add(LANDSCAPE_IMAGE_CLASS);
        }

        this.parent.dispatchEvent(eopCustomEvent("imageLoaded"));
    }
}