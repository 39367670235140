import {isPresent} from "../../../common/utils/basics";
import {absoluteHrefFrom} from "../../../common/utils/url";
import {resolve} from "../../../container";
import {schedule} from "../../../common/utils/promises";
import type {PageOverlayHandler} from "../pageOverlay/pageOverlayHandler";
import {PageOverlayHandlerProvider} from "../pageOverlay/pageOverlayHandler";

export class EopDynamicBackLink extends HTMLElement {

    private linkElement: Element;
    private pageOverlayHandler: PageOverlayHandler;

    public constructor(private pageOverlayHandlerProvider: PageOverlayHandlerProvider = resolve(PageOverlayHandlerProvider)) {
        super();
    }

    public connectedCallback(): void {
        this.linkElement = this.querySelector("a")!;
        schedule(this.setup()).as("dynamic-backlink-init");
    }

    private async setup(): Promise<void> {
        this.pageOverlayHandler = await this.pageOverlayHandlerProvider.getPageOverlayHandler();
        this.populateBacklink();
        this.setupSmoothOverlayClosing();
    }

    private populateBacklink(): void {
        const configuredTargets = this.readConfiguredTargets();
        const fallbackTarget: PageTarget = this.readFallbackTarget() ?? PageTarget.empty();
        const backLink = this.determineBackLink(configuredTargets, fallbackTarget);

        this.linkElement.setAttribute("href", backLink.href);
        this.linkElement.innerHTML = backLink.label;
    }

    private readConfiguredTargets(): BackLinkTarget[] {
        return (this.getAttribute("back-links")?.parseAsJSON<RawBackLinkTarget[]>() ?? [])
            .map(raw => BackLinkTarget.from(raw))
            .filter(isPresent);
    }

    private readFallbackTarget(): PageTarget | null {
        const backLinkFallbackHref = this.getAttribute("back-link-default-href");
        const backLinkFallbackLabel = this.getAttribute("back-link-default-label");
        if (backLinkFallbackHref && backLinkFallbackLabel) {
            return new PageTarget(backLinkFallbackHref, backLinkFallbackLabel);
        } else {
            return null;
        }
    }

    private determineBackLink(configuredTargets: BackLinkTarget[], fallbackTarget: PageTarget): BackLink {
        let backLink: BackLink = {href: fallbackTarget.href, label: fallbackTarget.label};

        const predecessorURL = this.getPredecessorURL();
        if (predecessorURL) {
            const matchingTarget = this.findBestMatchingTarget(configuredTargets, predecessorURL);
            if (matchingTarget) {
                backLink = {href: predecessorURL.href, label: matchingTarget.label};
            }
        }
        return backLink;
    }

    private getPredecessorURL(): URL | null {
        const href = this.pageOverlayHandler.getPredecessorURL();
        return href ? new URL(href) : null;
    }

    private findBestMatchingTarget(configuredTargets: BackLinkTarget[], predecessor: URL): BackLinkTarget | null {
        let bestTarget: BackLinkTarget | null = null;
        let bestMatch: URLMatch = NO_MATCH;

        for (const configuredTarget of configuredTargets) {
            const match = configuredTarget.matchPredecessorUrl(predecessor);
            if (match.matchesBetterThan(bestMatch)) {
                bestMatch = match;
                bestTarget = configuredTarget;
            }
        }
        return bestTarget;
    }

    private setupSmoothOverlayClosing(): void {
        this.linkElement.addEventListener("click", event => {
            const targetHref = this.linkElement.getAttribute("href")!;
            const didClose = this.pageOverlayHandler.closeOverlayIfHrefMatchesHost(
                absoluteHrefFrom(targetHref),
                (href1, href2) => this.linkHrefMatchesHostHref(href1, href2));
            if (didClose) {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }

    private linkHrefMatchesHostHref(linkTarget: string, hostHref: string): boolean {
        const linkUrl = new URL(linkTarget);
        const hostUrl = new URL(hostHref);

        return linkUrl.origin === hostUrl.origin
            && linkUrl.pathname === hostUrl.pathname;
    }
}

type RawBackLinkTarget = {
    href?: string;
    area?: string;
    label: string;
};

type BackLink = {
    href: string;
    label: string;
};

abstract class BackLinkTarget {
    protected constructor(public readonly label: string) {
    }

    public abstract matchPredecessorUrl(predecessor: URL): URLMatch;

    public static from(raw: RawBackLinkTarget): BackLinkTarget | null {
        if (!raw.label) {
            return null;
        }
        if (raw.href) {
            return new PageTarget(raw.href, raw.label);
        }
        if (raw.area) {
            return new AreaTarget(raw.area, raw.label);
        }
        return null;
    }
}

class PageTarget extends BackLinkTarget {

    private absoluteUrl: URL;

    public constructor(public readonly href: string, label: string) {
        super(label);
        this.absoluteUrl = new URL(absoluteHrefFrom(href));
    }

    public static empty(): PageTarget {
        return new PageTarget("", "");
    }

    public matchPredecessorUrl(predecessor: URL): URLMatch {
        return (this.absoluteUrl.origin === predecessor.origin && this.absoluteUrl.pathname === predecessor.pathname)
            ? OPTIMAL_MATCH
            : NO_MATCH;
    }
}

class AreaTarget extends BackLinkTarget {

    private absoluteAreaLocation: URL;

    public constructor(areaHref: string, label: string) {
        super(label);
        this.absoluteAreaLocation = new URL(absoluteHrefFrom(areaHref));
    }

    public matchPredecessorUrl(predecessor: URL): URLMatch {
        return (this.absoluteAreaLocation.origin === predecessor.origin && predecessor.pathname.startsWith(this.absoluteAreaLocation.pathname))
            ? URLMatch.match(predecessor.pathname.length - this.absoluteAreaLocation.pathname.length)
            : NO_MATCH;
    }
}

class URLMatch {

    public constructor(private readonly diff: number, private readonly isMatching: boolean) {
    }

    public static match(diff: number): URLMatch {
        return new URLMatch(diff, true);
    }

    public static noMatch(): URLMatch {
        return new URLMatch(Number.MAX_SAFE_INTEGER, false);
    }

    public matchesBetterThan(other: URLMatch): boolean {
        return this.isMatching && this.diff < other.diff;
    }
}

const OPTIMAL_MATCH: URLMatch = URLMatch.match(Number.MIN_SAFE_INTEGER);
const NO_MATCH: URLMatch = URLMatch.noMatch();

customElements.define("eop-dynamic-backlink", EopDynamicBackLink);