import {extendPrototype} from "../../common/utils/extend";
import type {PrimitiveObject, PrimitiveType} from "../../common/utils/objects";
import {floatFrom, intFrom} from "./numbers";

export const ELLIPSIS = "…";
export const ELLIPSIS_IN_SQUARE_BRACKETS = "[…]";

// regexes containing a top level group let split return also the split tokens
const WORD_TERMINATED = /([^ ]+ +)/;

extendPrototype(String, {

    truncateAfter: function (this: string, length: number): string {
        return (this.length > length) ? this.substring(0, length) : this;
    },

    truncateWordsAfter: function (this: string, length: number, terminal: string = ELLIPSIS): string {
        const wordsWithSpaces = this.split(WORD_TERMINATED)
            .filter(wordWithSpace => wordWithSpace.length > 0);

        let shortenedText = wordsWithSpaces.shift() ?? "";
        while (wordsWithSpaces.length > 0) {
            const wordWithSpaces = wordsWithSpaces.shift();

            const newShortenedText = shortenedText + wordWithSpaces;
            if (newShortenedText.length > length) {
                return shortenedText + terminal;
            }
            shortenedText = newShortenedText;
        }
        return this;
    },

    toInt: function (this: string): number {
        return intFrom(this);
    },

    toFloat: function (this: string): number {
        return floatFrom(this);
    },

    parseAsJSON: function <T extends Object | Array<any> | PrimitiveType = PrimitiveObject>(this: string): T {
        return JSON.parse(this);
    },

    parseCommaSeparated: function (this: string): string[] {
        return this.split(",")
            .map(s => s.trim())
            .filter(s => !!s);
    }
});

export function isString(value: any): value is string {
    return typeof value === "string";
}

export function escapedPatternRegExp(rawPattern: string, flags?: string): RegExp {
    const escapedPattern = rawPattern.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
    return new RegExp(escapedPattern, flags);
}

declare global {
    interface String {
        truncateAfter: (length: number) => string;

        truncateWordsAfter: (length: number, ellipsis?: string) => string;

        toInt: () => number;

        toFloat: () => number;

        parseAsJSON: <T extends Object | Array<any> | PrimitiveType = PrimitiveObject>() => T;

        parseCommaSeparated: () => string[];
    }
}