import {autoRegister, resolve} from "../../container";
import {Timeout} from "../../common/timeout";
import {Tracking} from "./tracking";

class PercentageRange {

    public constructor(private lowerBound: number, private upperBound: number) {
    }

    public includes(percentage: number): boolean {
        return percentage > this.lowerBound && percentage < this.upperBound;
    }

    public label(): string {
        return `${this.lowerBound}_percent`;
    }
}

@autoRegister()
export class VideoTrackingFactory {

    public constructor(
        private tracking: Tracking = resolve(Tracking),
        private timeout: Timeout = resolve(Timeout)
    ) {
    }

    public create(title: string, progress: VideoProgress): VideoTracking {
        return new VideoTracking(this.tracking, this.timeout, title, progress);
    }
}

export abstract class VideoProgress {
    public duration: () => number;
    public currentTime: () => number;

    public percentagePlayed(): number {
        return (this.currentTime() / this.duration()) * 100;
    }
}

export class VideoTracking {

    private readonly percentages: PercentageRange[];
    private trackingPromise: Promise<void>;

    private isPlaying: boolean;

    public constructor(
        private tracking: Tracking,
        private timeout: Timeout,
        private title: string,
        private progress: VideoProgress
    ) {
        this.percentages = [new PercentageRange(25, 50), new PercentageRange(50, 75), new PercentageRange(75, 90), new PercentageRange(90, 100)];
    }

    public playing(): void {
        this.isPlaying = true;
        this.track("playing");
        this.triggerPercentagePlayedTracking();
    }

    public pause(): void {
        this.isPlaying = false;
        this.track("paused");
    }

    public ended(): void {
        this.isPlaying = false;
        this.track("ended");
    }

    private track(event: string): void {
        this.tracking.videoEvent({
            videoEvent: event,
            videoTitle: this.title ?? ""
        });
    }

    public disconnect(): void {
        this.timeout.cancel(this.trackingPromise);
    }

    private triggerPercentagePlayedTracking(): void {
        this.trackingPromise = this.timeout.delay(() => this.checkCurrentPercentagePlayed(), 1000);
    }

    private checkCurrentPercentagePlayed(): void {
        const currentPercentage = this.percentages
            .findFirst(range => range.includes(this.progress.percentagePlayed()));

        if (currentPercentage) {
            this.track(currentPercentage.label());
            this.percentages.removeAll(currentPercentage);
        }

        if (this.isPlaying && this.percentages.isNotEmpty()) {
            this.triggerPercentagePlayedTracking();
        }
    }
}