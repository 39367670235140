import {noop} from "../../common/utils/functions";
import {GLOBAL} from "../../common/globals";
import {resolve} from "../../container";
import {EOP_ERRORS, Promises} from "../../common/utils/promises";
import {Timeout} from "../../common/timeout";
import {elementFrom} from "../../common/utils/html";
import SPINNER_ICON from "../../../../resources/assets/images/icon_spinner.svg";
import {allPropertiesOf, prototypeChainOf} from "../../common/utils/objects";

export {SPINNER_ICON};
export const MINIMUM_SPINNER_DURATION = 500;

export interface Spinner {
    eventIds: () => string[];

    showSpinner: () => void;

    hideSpinner: () => void;

    spinWhile: <T>(promise: Promise<T>) => Promise<T>;
}

function spinnerImageWith(classes: string[] = []): Element {
    const iconElement = elementFrom(SPINNER_ICON);
    iconElement.classList.add("spinner", ...classes);
    return iconElement;
}

export abstract class AbstractSpinner extends HTMLElement implements Spinner {

    private events: string[];
    private scope: string;

    protected constructor(private promises: Promises) {
        super();
    }

    public withClass(givenClass: string): this {
        this.classList.add(givenClass);
        return this;
    }

    public eventIds(): string[] {
        return this.events;
    }

    public spinner(): Spinner {
        return this;
    }

    public abstract connectedSpinner(): void;

    public connectedCallback(): void {
        this.events = (this.getAttribute("event") ?? "spinner").split(",").map(part => part.trim());

        this.connectedSpinner();

        const scopedPromises = this.promises.forScope("global");
        for (const event of this.events) {
            scopedPromises.on(event, p => this.spinWhile(p));
        }
    }

    public abstract disconnectedSpinner(): void ;

    public disconnectedCallback(): void {
        this.disconnectedSpinner();
        if (this.scope) {
            this.promises.forScope(this.scope).clear(this.events);
        }
    }

    public async spinWhile<T>(promise: Promise<T>): Promise<T> {
        const spinner = this.spinner();
        spinner.showSpinner();
        try {
            return await promise;
        } finally {
            spinner.hideSpinner();
        }
    }

    public abstract showSpinner(): void;

    public abstract hideSpinner(): void;

}

export class EopOverlaySpinner extends AbstractSpinner implements Spinner {

    private spinnerElement: HTMLElement;
    private spinnerDecorator: MinimumShowSpinnerDecorator;

    public constructor(promises: Promises = resolve(Promises)) {
        super(promises);
        this.spinnerDecorator = new MinimumShowSpinnerDecorator(this, MINIMUM_SPINNER_DURATION);
    }

    public spinner(): Spinner {
        return this.spinnerDecorator;
    }

    public connectedSpinner(): void {
        this.spinnerElement = document.createElement("div");
        this.spinnerElement.classList.add("spinner-container", "overlay-spinner");

        const spinnerIconElement = elementFrom(SPINNER_ICON);
        spinnerIconElement.classList.add("spinner", "spinner-lg");

        this.spinnerElement.append(spinnerIconElement);

        GLOBAL.bodyElement().appendChild(this.spinnerElement);
    }

    public disconnectedSpinner(): void {
        this.spinnerElement.remove();
    }

    public showSpinner(): void {
        this.spinnerElement.show();
    }

    public hideSpinner(): void {
        this.spinnerElement.hide();
    }
}

export class EopImageSpinner extends AbstractSpinner implements Spinner {

    public constructor(promises: Promises = resolve(Promises)) {
        super(promises);
    }

    public connectedSpinner(): void {
        if (!this.replaceChildren) { // TODO remove debugInfo asap - #3222308
            throw Error(`replaceChildren is not defined in EopImageSpinner. Prototype chain: ${prototypeChainOf(this)}. All properties: ${allPropertiesOf(this)}`);
        }
        this.replaceChildren(spinnerImageWith());
    }

    public disconnectedSpinner(): void {
        this.replaceChildren();
    }

    public showSpinner(): void {
        this.show();
    }

    public hideSpinner(): void {
        this.hide();
    }
}

export class EopOverlayImageSpinner extends AbstractSpinner implements Spinner {

    private spinnerDecorator: MinimumShowSpinnerDecorator;

    public constructor(promises: Promises = resolve(Promises)) {
        super(promises);
        this.spinnerDecorator = new MinimumShowSpinnerDecorator(this, MINIMUM_SPINNER_DURATION);
    }

    public spinner(): Spinner {
        return this.spinnerDecorator;
    }

    public connectedSpinner(): void {
        const spinnerClasses = Array.from(this.classList).filter(cls => cls.startsWith("spinner"));
        if (!this.replaceChildren) { // TODO remove debugInfo asap - #3222308
            throw Error(`debug: replaceChildren is not defined in EopOverlayImageSpinner. Prototype chain: ${prototypeChainOf(this)}. All properties: ${allPropertiesOf(this)}`);
        }
        this.replaceChildren(spinnerImageWith(spinnerClasses));
        this.classList.add("spinner-container");
    }

    public disconnectedSpinner(): void {
        this.replaceChildren();
    }

    public showSpinner(): void {
        this.show();
    }

    public hideSpinner(): void {
        this.hide();
    }
}

export class EopWrapperImageSpinner extends AbstractSpinner implements Spinner {

    private spinnerDecorator: MinimumShowSpinnerDecorator;
    private img: Element;
    private content: Element;

    public constructor(promises: Promises = resolve(Promises)) {
        super(promises);
        this.spinnerDecorator = new MinimumShowSpinnerDecorator(this, MINIMUM_SPINNER_DURATION);
        this.content = this.querySelector(".spinner-toggled-content")!;
    }

    public spinner(): Spinner {
        return this.spinnerDecorator;
    }

    public connectedSpinner(): void {
        const spinnerClasses = Array.from(this.classList).filter(cls => cls.startsWith("spinner"));
        this.img = spinnerImageWith(spinnerClasses);
        this.img.hide();
        this.append(this.img);
    }

    public disconnectedSpinner(): void {
        this.img.remove();
    }

    public showSpinner(): void {
        this.content.hide();
        this.img.show();
    }

    public hideSpinner(): void {
        this.content.show();
        this.img.hide();
    }
}

export class MinimumShowSpinnerDecorator implements Spinner {
    private minimumShowDurationReached: Promise<void>;

    public constructor(
        public delegate: Spinner,
        public minimumShowDuration: number,
        private timeout: Timeout = resolve(Timeout)
    ) {
        this.minimumShowDurationReached = Promise.resolve();
    }

    public eventIds(): string[] {
        return this.delegate.eventIds();
    }

    public showSpinner(): void {
        this.delegate.showSpinner();

        this.timeout.cancel(this.minimumShowDurationReached);
        this.minimumShowDurationReached = this.timeout.delay(noop, this.minimumShowDuration);
    }

    public hideSpinner(): void {
        this.minimumShowDurationReached
            .then(() => this.delegate.hideSpinner())
            .catch(EOP_ERRORS);
    }

    public spinWhile<T>(promise: Promise<T>): Promise<T> {
        return promise.finally(noop);
    }

}

customElements.define("eop-overlay-spinner", EopOverlaySpinner);
customElements.define("eop-image-spinner", EopImageSpinner);
customElements.define("eop-overlay-image-spinner", EopOverlayImageSpinner);
customElements.define("eop-wrapper-image-spinner", EopWrapperImageSpinner);