import {GLOBAL} from "./globals";
import {autoRegister} from "../container";

@autoRegister()
export class DeviceDetectorService {
    public constructor(private window: Window = GLOBAL.window()) {
    }

    public isMobile(): boolean {
        return /Android|BlackBerry|BB|iPhone|iPad|iPod|Mobile|CrOS|Windows Phone|IEMobile|Opera Mini|Vita/i.test(this.userAgent());
    }

    public isAndroid(): boolean {
        return /Android/i.test(this.userAgent()) && !/Windows Phone/i.test(this.userAgent());
    }

    public isIOS(): boolean {
        return /iPad|iPhone|iPod/i.test(this.userAgent()) && !(this.window as any).MSStream;
    }

    public userAgent(): string {
        return this.window.navigator.userAgent ?? "";
    }
}