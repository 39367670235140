import type {ButtonInteractData, LinkInteractData, LinkTrackingData, TrackingListener} from "../listener";
import {Tracking} from "../tracking";
import {autoRegister, resolve} from "../../container";
import {NativeGoogleTagManager} from "./nativeGtm";

@autoRegister()
export class GoogleTagManagerTracking {
    public constructor(private nativeGoogleTagManager: NativeGoogleTagManager = resolve(NativeGoogleTagManager)) {
    }

    public sendTextlinkInteractEvent(event: LinkInteractData): void {
        this.nativeGoogleTagManager.push(this.linkEventFrom("textlink_interact", {
            text: event.label,
            type: "click",
            cms_id: event.contentId
        }, event.linkData));
    }

    public sendButtonInteractEvent(event: ButtonInteractData): void {
        this.nativeGoogleTagManager.push(this.linkEventFrom("button_interact", {
            text: event.label,
            type: "click",
            cms_id: event.contentId
        }, event.linkData));
    }

    private linkEventFrom(name: string, data: any, linkData?: LinkTrackingData): any {
        const event = this.eventFrom(name, data);
        if (linkData) {
            event[name].link_target_url = linkData.targetUrl;
            event[name].link_direction = this.linkDirection(linkData.isExternal);
        }
        return event;
    }

    private eventFrom(name: string, data: any): any {
        return {
            event: "generic_event",
            event_name: name,
            [name]: data
        };
    }

    private linkDirection(isExternal: boolean): string {
        return isExternal ? "external" : "internal";
    }
}

export class GoogleTagManagerTrackingListener implements TrackingListener {

    public constructor(private googleTagManagerTracking: GoogleTagManagerTracking) {
    }

    public textlinkInteract(event: LinkInteractData): void {
        this.googleTagManagerTracking.sendTextlinkInteractEvent(event);
    }

    public buttonInteract(event: ButtonInteractData): void {
        this.googleTagManagerTracking.sendButtonInteractEvent(event);
    }
}

@autoRegister()
export class GoogleTagManagerTrackingListenerFactory {
    public constructor(private googleTagManagerTracking: GoogleTagManagerTracking = resolve(GoogleTagManagerTracking)) {
    }

    public createListener(): GoogleTagManagerTrackingListener {
        return new GoogleTagManagerTrackingListener(this.googleTagManagerTracking);
    }
}

export class EopGoogleTagManagerTracking extends HTMLElement {

    public constructor(
        private tracking: Tracking = resolve(Tracking),
        private googleTagManagerTrackingListenerFactory: GoogleTagManagerTrackingListenerFactory = resolve(GoogleTagManagerTrackingListenerFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        const googleTagManagerTrackingListener = this.googleTagManagerTrackingListenerFactory.createListener();
        this.tracking.registerUnnamedListener(googleTagManagerTrackingListener);
    }
}

customElements.define("eop-google-tag-manager-tracking", EopGoogleTagManagerTracking);