import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, queryAssignedElements} from "lit/decorators.js";
import Styles from "./macherbusRanking.lit.scss";
import type {EopMacherbusRankedCategory} from "./macherbusRankedCategory";
import {noop} from "../../common/utils/functions";
import {Promises, schedule} from "../../common/utils/promises";
import {resolve} from "../../container";
import {Initializing} from "../../common/elements";

@customElement("eop-macherbus-ranking")
export class EopMacherbusRanking extends Initializing(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "voting-id"})
    private votingId: string;
    @queryAssignedElements({slot: "macherbus-category"})
    private categories: Array<EopMacherbusRankedCategory>;

    public constructor(private promises: Promises = resolve(Promises)) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <eop-overlay-spinner event="ranked" class="ranking-spinner"></eop-overlay-spinner>
            <slot name="macherbus-category" class="macherbus-categories"></slot>
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.init().then(noop);
    }

    private async init(): Promise<void> {
        const rankProjects = Promise.all(this.categories.map(c => c.rankProjects(this.votingId)));
        const spinnerForRanking = this.promises.decoratorFor("ranked")(rankProjects);
        await schedule(spinnerForRanking).as("ranking");
        this.initialized();
    }
}