import {noop} from "./utils/functions";
import {Timeout} from "./timeout";
import {autoRegister, resolve} from "../container";
import {schedule} from "./utils/promises";

export class DelayableOnceAction {
    private finished: boolean;
    private delayedAction: Promise<void>;

    public constructor(
        private rawAction: () => void,
        private condition: () => boolean,
        private timeout: Timeout
    ) {
        this.finished = false;
    }

    public trigger(delay: number): void {
        this.triggerIf(this.readyForAction(), delay);
    }

    public triggerIf(shouldRestart: boolean, delay: number): void {
        this.timeout.cancel(this.delayedAction);
        if (shouldRestart) {
            this.delayedAction = this.startActionTimer(delay);
            schedule(this.delayedAction).as("delayed-action");
        }
    }

    private startActionTimer(delay: number): Promise<void> {
        return this.timeout.delay(() => {
            if (this.readyForAction()) {
                this.performAction();
            }
        }, delay);
    }

    public hasFinished(): boolean {
        return this.finished;
    }

    private readyForAction(): boolean {
        return !this.finished && this.condition();
    }

    private performAction(): void {
        this.rawAction();
        this.finished = true;
    }
}

export class DelayableOnceActionConfig {
    public rawAction: () => void;
    public condition: () => boolean;

    public constructor() {
        this.rawAction = noop;
        this.condition = () => true;
    }

    public withAction(rawAction: () => void): this {
        this.rawAction = rawAction;
        return this;
    }

    public withCondition(condition: () => boolean): this {
        this.condition = condition;
        return this;
    }
}

@autoRegister()
export class DelayableOnceActionFactory {
    public constructor(private timeout: Timeout = resolve(Timeout)) {
    }

    public newDelayableOnceAction(config: DelayableOnceActionConfig): DelayableOnceAction {
        return new DelayableOnceAction(config.rawAction, config.condition, this.timeout);
    }
}