import {GLOBAL} from "../../../common/globals";
import {autoRegister, resolve} from "../../../container";
import {Visibility} from "../../../common/visibility";
import {isDefined, isPresent} from "../../../common/utils/basics";
import {eopCustomEvent} from "../../../common/eventBus";
import {forAllEntries, IntersectionObserverFactory} from "../../../common/observation";
import {noop} from "../../../common/utils/functions";
import type {Lifetime} from "../../../common/lifetime";
import {schedule} from "../../../common/utils/promises";
import {SmoothScrolling} from "../../../common/smoothScrolling";

const CHAPTER_ATTRIBUTE = "chapter-section";
export const VISIBLE_HEIGHT_TO_BECOME_ACTIVE = 175;
export const CHAPTER_LINK_EVENT = "chapterLink";

export class ChapterData {
    public constructor(
        public name: string,
        public href: string,
        public index: number,
        public active: boolean
    ) {
    }

    public static from(chapter: Chapter): ChapterData {
        return new ChapterData(chapter.name, chapter.getHref(), chapter.index, chapter.active);
    }
}

export class Chapter {
    public active: boolean;

    public constructor(
        public readonly name: string,
        public readonly id: string,
        public readonly index: number,
        public readonly elements: Element[]
    ) {
        this.active = false;
    }

    public static fromSection(section: Element, index: number): Chapter | null {
        const isStartSection = section.hasAttribute(CHAPTER_ATTRIBUTE);
        if (!isStartSection) {
            return null;
        }
        return new Chapter(section.getAttribute(CHAPTER_ATTRIBUTE) ?? "", section.id, index, []);
    }

    public assignSection(section: Element): void {
        this.elements.push(section);
    }

    public getHref(): string {
        return "#" + this.id;
    }

    public isActive(): boolean {
        return this.active;
    }

    public toggleActive(value: boolean): void {
        this.active = value;
    }
}

@autoRegister()
export class Chapters {

    private chapters: Chapter[];
    private intersectingChaptersObserver: IntersectionObserver;
    private fullyVisibleSectionsObserver: IntersectionObserver;

    private progressing: Promise<void> | null;
    private reactToChapterChange: () => void;

    public constructor(
        private visibility: Visibility = resolve(Visibility),
        private smoothScrolling: SmoothScrolling = resolve(SmoothScrolling),
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        this.chapters = this.determineChapters();
        this.reactToChapterChange = noop;
        this.progressing = null;
        this.intersectingChaptersObserver = this.intersectionObserverFactory.create(
            forAllEntries(() => this.updateIndex()),
            {rootMargin: `-${VISIBLE_HEIGHT_TO_BECOME_ACTIVE}px 0px`}, "intersecting");
        this.fullyVisibleSectionsObserver = this.intersectionObserverFactory.create(forAllEntries(_ => this.updateIndex()), {threshold: 1}, "fullyVisible");

        GLOBAL.bodyElement().addEventListener(CHAPTER_LINK_EVENT, event => this.jumpTo((event as ChapterLinkEvent).detail.chapter));
    }

    private jumpTo(chapter: ChapterData): void {
        const index = chapter.index;
        schedule(this.after(() => this.smoothScrolling.scrollToAnchor(chapter.href)).select(index))
            .as("chapter-navigation-jump")
            .then(() => this.updateIndex(index));
    }

    private after(process: () => Promise<void>): { select: (index: number) => Promise<void> } {
        this.progressing = process().then(() => {
            this.progressing = null;
        });
        return {
            select: index => this.progressing!.then(() => this.updateIndex(index))
        };
    }

    private updateIndex(newIndex?: number | undefined): void {
        if (!this.progressing) {
            const index = newIndex ?? this.activeChapterIndex();
            const changed = this.setActive(index);
            if (changed) {
                this.reactToChapterChange();
            }
        }
    }

    public onChapterChange(callback: () => void, lifetime?: Lifetime): void {
        this.reactToChapterChange = () => callback();

        const chapterElements = this.chapters
            .flatMap(chapter => chapter.elements)
            .filter(isPresent)
            .distinct();

        chapterElements.forEach(element => {
            this.intersectingChaptersObserver.observe(element);
            this.fullyVisibleSectionsObserver.observe(element);
        });
        lifetime?.onDrop(() => {
            this.intersectingChaptersObserver.disconnect();
            this.fullyVisibleSectionsObserver.disconnect();
        });
        this.updateIndex();
    }

    public activeChapterIndex(): number | undefined {
        return this.determineActiveChapter()?.index;
    }

    public getChapterData(): ChapterData[] {
        return this.chapters.map(chapter => ChapterData.from(chapter));
    }

    private setActive(activeIndex: number | undefined): boolean {
        if (isDefined(activeIndex) && this.chapters[activeIndex].isActive()) {
            return false;
        }

        this.chapters.forEach((chapter, index) => chapter.toggleActive(index === activeIndex));
        return true;
    }

    private determineActiveChapter(): Chapter | undefined {
        const candidates = this.chapters
            .map(chapter => this.chapterVisibility(chapter))
            .filter(markedChapter => markedChapter.visibleEnough);

        const candidate = candidates.findFirst(markedChapter => markedChapter.visibleCompletely)
            ?? candidates.first();

        return candidate?.chapter;
    }

    private determineChapters(): Chapter[] {
        const sections = GLOBAL.bodyElement().querySelectorAll("section");
        const chapters: Chapter[] = [];
        let currentChapter: Chapter | undefined;
        let index = 0;
        for (const section of sections) {
            const nextChapter = Chapter.fromSection(section, index);
            if (nextChapter && nextChapter.name !== "") {
                chapters.push(nextChapter);
                currentChapter = nextChapter;
                index++;
            }
            currentChapter?.assignSection(section);
        }
        return chapters;
    }

    private chapterVisibility(chapter: Chapter): ChapterVisibility {
        if (!this.chapterIntersectsViewport(chapter.elements)) {
            return {chapter: chapter, visibleEnough: false, visibleCompletely: false};
        }

        const visibleChapterHeight = chapter.elements
            .map(chapterElement => this.visibility.getViewportIntersectionHeight(chapterElement))
            .reduce((x, y) => x + y, 0);
        if (visibleChapterHeight < VISIBLE_HEIGHT_TO_BECOME_ACTIVE) {
            return {chapter: chapter, visibleEnough: false, visibleCompletely: false};
        }

        const insideViewport = chapter.elements
            .every(chapterElement => this.visibility.isInsideViewport(chapterElement));
        return {chapter: chapter, visibleEnough: true, visibleCompletely: insideViewport};
    }

    private chapterIntersectsViewport(chapterElements: Element[]): boolean {
        return chapterElements.some(chapterElement => this.visibility.intersectsViewport(chapterElement));
    }
}

type ChapterVisibility = {
    chapter: Chapter;
    visibleEnough: boolean;
    visibleCompletely: boolean;
}

export type ChapterLinkEvent = CustomEvent<{ chapter: ChapterData }>;

export function chapterLinkEvent(chapter: ChapterData): ChapterLinkEvent {
    return eopCustomEvent(CHAPTER_LINK_EVENT, {chapter: chapter});
}