import {isFunction} from "./functions";
import extend from "extend";
import merge from "lodash.merge";

export function compactKeepingFunctions(object: PropertyMap): PropertyMap {
    return compactAccordingToKeepingCriterion<any>(object, value => value !== undefined);
}

export function compact(object: PropertyMap): PrimitiveObject {
    return compactAccordingToKeepingCriterion<PrimitiveType>(object, value => value !== undefined && !isFunction(value));
}

function compactAccordingToKeepingCriterion<ENTRY>(object: NestedMap<ENTRY>, shouldKeep: (value: any) => boolean): NestedMap<ENTRY> {
    const result: NestedMap<ENTRY> = {};
    for (const property of Object.keys(object)) {
        const value = object[property];
        if (shouldKeep(value)) {
            result[property] = value;
        }
    }
    return result;
}

export function generify(object: any): PrimitiveObject {
    if (isPrimitive(object) || isArray(object)) {
        return object;
    }
    const result: NestedMap<PrimitiveType> = {};
    for (const property of Object.keys(object)) {
        const value = object[property];
        if (value !== undefined && !isFunction(value)) {
            result[property] = generify(value);
        }
    }
    return result;
}


export function deepCopy<T>(item: T): T {
    return merge({}, item);
}

export function mergeDeep<T, U>(destination: T, source: U): T & U {
    return merge(destination, source);
}

export function mergeShallow<T, U>(destination: T, source: U): T & U {
    return extend(false, destination, source);
}

export type PrimitiveType = string | number | boolean | null | undefined;

export interface NestedMap<T> {
    [key: string]: T | NestedMap<T> | undefined;
}

export type PrimitiveObject = NestedMap<PrimitiveType>;

export function isPrimitiveObject(value: PrimitiveType | PrimitiveObject): value is PrimitiveObject {
    return isObject(value);
}

export function isPrimitiveType(value: PrimitiveType | PrimitiveObject): value is PrimitiveType {
    return isPrimitive(value);
}

export function isObject(value: any): value is Object {
    return value !== null && typeof value === "object";
}

export function isArray(value: any): value is Object {
    return value !== null && Array.isArray(value);
}

export function isPrimitive(value: any): value is Object {
    return value === null
        || typeof value === "string"
        || typeof value === "number"
        || typeof value === "boolean";
}

export function isMap(value: any): value is Map<any, any> {
    return value instanceof Map;
}

export interface PropertyMap<T = any> {
    [key: string]: T | undefined;
}

export interface Constructor<T> {
    new(...params: any): T; // do not use function type here
}

// so far only used for debugging - #3222308
export function allPropertiesOf(item: any): string[] {
    const result: string[] = [];
    for (const p in item) {
        result.push(p);
    }
    return result;
}

// so far only used for debugging - #3222308
export function prototypeChainOf(item: any): string[] {
    if (!item) {
        return [];
    }

    const result: string[] = [];
    let current = Object.getPrototypeOf(item);
    while (current) {
        result.push(current.constructor.name);
        current = Object.getPrototypeOf(current);
    }
    return result;
}