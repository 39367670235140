export function textBetween(element1: HTMLElement, element2: HTMLElement): string {
    if (!element1) {
        throw new Error("First element is missing!");
    }
    if (!element2) {
        throw new Error("Second element is missing!");
    }
    const parent = element1.parentElement!;
    if (element2.parentElement !== parent) {
        throw new Error("Elements do not have same parent");
    }

    const childNodes = [...parent.childNodes];
    const index1 = childNodes.indexOf(element1);
    const index2 = childNodes.indexOf(element2);
    if (index1 > index2) {
        throw new Error("Elements are in wrong order");
    }

    const nodesBetween = childNodes.slice(index1 + 1, index2);
    return nodesBetween
        .filter(e => e instanceof Text)
        .map(e => e.textContent)
        .join("");
}