import {isMap, type PropertyMap} from "../../common/utils/objects";

export type DictionaryElement = HTMLElement & {
    _dictionary?: Dictionary;
};

export class Dictionary {

    private parent: Dictionary | undefined;
    private messages: Map<string, string | Map<string, string>>;

    public constructor(parent?: Dictionary) {
        this.parent = parent;
        this.messages = new Map();
    }

    public static root(): Dictionary {
        return rootDictionary;
    }

    public static of(element: Element): Dictionary {
        const dictionaryElement = element.closestThat(elm => !!Dictionary.fetch(elm), {transparent: true});
        return dictionaryElement
            ? Dictionary.extractDictionary(dictionaryElement)!
            : Dictionary.root();
    }

    private static fetch(element: Element): Dictionary | undefined {
        let dictionary = Dictionary.extractDictionary(element);
        if (!dictionary) {
            const entries = [...element.children].filter(it => "eop-dictionary-entry" === it.tagName.toLowerCase());
            if (entries.length > 0) {
                const parentDictionary = element.parentElement ? Dictionary.of(element.parentElement) : undefined;
                dictionary = new Dictionary(parentDictionary).attachTo(element);
                entries.forEach(entry => dictionary!.addMessageFromEntry(entry));
            }
        }
        return dictionary;
    }

    private static extractDictionary(element: Element): Dictionary | undefined {
        return (element as DictionaryElement)._dictionary;
    }

    private attachTo(element: Element): this {
        (element as DictionaryElement)._dictionary = this;
        return this;
    }

    private addMessageFromEntry(entry: Element): void {
        const key = entry.getAttribute("key");
        const subKey = entry.getAttribute("sub-key");
        if (key) {
            this.addMessage(key, entry.innerHTML, subKey ?? undefined);
        }
    }

    public addMessage(key: string, value: string, subKey?: string): void {
        if (subKey) {
            let entry = this.messages.get(key);
            if (!isMap(entry)) {
                entry = new Map();
                this.messages.set(key, entry);
            }
            entry.set(subKey, value);
        } else {
            this.messages.set(key, value);
        }
    }

    public getMessage(key?: string, subkey: string = "_"): string | undefined {
        const value = key ? this.messages.get(key) : undefined;
        if (!value) {
            return this.parent?.getMessage(key, subkey);
        } else if (isMap(value)) {
            return value.get(subkey);
        } else {
            return value;
        }
    }

    public translate(key: string, subkey?: string, values?: PropertyMap): string {
        let translated = this.getMessage(key, subkey) ?? ("#" + key + "#");
        if (values) {
            for (const k in values) {
                const value = values[k];
                translated = translated.replace(k, value);
            }
        }
        return translated;
    }
}

const rootDictionary = new Dictionary();

export class EopMsg extends HTMLElement {

    private dictionary: Dictionary;

    public connectedCallback(): void {
        this.dictionary = Dictionary.of(this.parentElement!);

        const key = this.getAttribute("key") ?? undefined;
        const subKey = this.getAttribute("sub-key") ?? undefined;
        const value = this.dictionary.getMessage(key, subKey);
        if (!value) {
            console.error(`dictionary key <${key}> missing.`);
        }
        this.outerHTML = value ?? ("#" + key + "#");
    }
}

customElements.define("eop-msg", EopMsg);