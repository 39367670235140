import {Resolution} from "../../../common/resolution";
import {IMAGE_BREAKPOINTS} from "../../../common/resolutionConstants";
import {Cookieflag} from "../../../common/cookieflag";
import {isPresent} from "../../../common/utils/basics";
import {resolve} from "../../../container";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query, state} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ManagingResources} from "../../../common/lifetime";
import {UnLitElement} from "../../../common/elements";
import {PlainImageLoadListener} from "./plainImageCommons";

@customElement("eop-plain-artdirected-image")
export class EopPlainArtDirectedImage extends ManagingResources(UnLitElement) {

    @property({attribute: "src"})
    public src: string;
    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: "reverse-src-order"})
    public reverseSrcOrder: boolean = false;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;

    @state()
    protected srcCurrent: string;

    @query(".plain-image")
    private imageElement: HTMLImageElement;

    private imageLoadListener: PlainImageLoadListener;

    public constructor(
        private cookieflag: Cookieflag = resolve(Cookieflag),
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
        this.imageLoadListener = new PlainImageLoadListener(() => this.imageElement, this);
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.srcCurrent = this.src ?? "";

        if (this.cookieflag.isSetFor("enbw-disable-responsive-image")) {
            return;  // used for layout tests
        }

        this.updateImage(this.resolution.getBreakpoint());
        this.resolution.onWindowResize(breakpoint => this.updateImage(breakpoint), this);
    }

    public render(): TemplateResult {
        return html`<img src=${this.outOfView ? "" : this.srcCurrent}
                         alt=${this.alt ?? ""}
                         loading="lazy"
                         @load=${{handleEvent: () => this.imageLoadListener.imageLoaded(this.src), once: true}}
                         class="plain-image"/>`;
    }

    private updateImage(newBreakpoint: number): void {
        let breakpointSource: string | null;
        if (this.reverseSrcOrder) {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => newBreakpoint < breakpoint.min)
                .map(breakpoint => this.getAttribute("src-" + breakpoint.min))
                .filter(isPresent)
                .first();
        } else {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => newBreakpoint >= breakpoint.min)
                .map(breakpoint => this.getAttribute("src-" + breakpoint.min))
                .filter(isPresent)
                .last();
        }

        this.srcCurrent = breakpointSource ?? this.src;
    }
}