export const ELLIPSIS = "…";

export function truncateWordsAfter(element: HTMLElement, maxLength: number): void {
    const text = element.textContent ?? "";
    let excess = text.length - maxLength;

    if (excess <= 0) {
        return;
    }

    if (excess > 0) {
       const truncated = text.truncateWordsAfter(maxLength, "").trim();
       excess = text.length - truncated.length - 1;
    }

    const reversedContentElements = [...element.childNodes].reverse();

    for (const node of reversedContentElements) {
        if (node instanceof Text) {
            const elementText = node.textContent ?? "";
            const textLength = elementText.length;

            if (textLength <= excess) {
                excess -= textLength;
                node.remove();
                continue;
            }

            const splitText = node.splitText(textLength - excess - 1);
            splitText.replaceWith(ELLIPSIS);
        } else if (node instanceof HTMLElement) {
            const elementText = node.textContent ?? "";
            const textLength = elementText.length;

            if (textLength <= excess) {
                excess -= textLength;
                node.remove();
                continue;
            }

            truncateWordsAfter(node, textLength - excess);
        }
        break;
    }
}